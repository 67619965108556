<template>
  <div class="product-list-wrap">
    <div class="head-operat" v-if="isSearch || total != 0">
      <vh-button
        type="primary"
        size="medium"
        round
        class="head-btn set-upload"
        @click="createProduct"
        v-if="!isInWebinar && hasGoodsEditPermission"
      >
        创建商品
      </vh-button>
      <vh-button
        type="primary"
        size="medium"
        round
        class="head-btn set-upload"
        @click="openMaterial"
        v-if="isInWebinar"
      >
        资料库
      </vh-button>
      <vh-button
        round
        size="medium"
        type="info"
        plain
        class="head-btn set-upload"
        @click="setStatus()"
        :disabled="selectIds.length <= 0"
        v-if="isInWebinar"
      >
        批量上下架
      </vh-button>
      <vh-button
        round
        size="medium"
        type="info"
        plain
        :class="['transparent-btn', { 'no-data': selectIds.length <= 0 }]"
        :disabled="selectIds.length <= 0"
        @click="onDelete('')"
        v-if="hasGoodsEditPermission"
      >
        批量删除
      </vh-button>
      <vh-input
        round
        size="medium"
        @keyup.enter.native="getList('clearPage')"
        clearable
        :maxlength="30"
        @clear="getList('clearPage')"
        style="width: 220px"
        class="head-btn fr search-tag"
        v-model="searchParams.searchName"
        autocomplete="off"
        placeholder="请输入商品名称"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="getList('clearPage')"></i>
      </vh-input>
    </div>
    <div v-if="!total && !isSearch" class="oh">
      <null-page
        :nullType="'nullData'"
        :text="isInWebinar ? '您还没有商品，请先选择商品～' : '您还没有商品，快来创建吧！'"
      >
        <vh-button
          v-if="!isInWebinar"
          size="medium"
          type="primary"
          round
          @click="createProduct"
          v-preventReClick
        >
          创建商品
        </vh-button>
        <vh-button v-else size="medium" type="primary" round @click="openMaterial" v-preventReClick>
          资料库
        </vh-button>
      </null-page>
    </div>
    <vh-table
      @select-all="onSelect"
      empty-text=""
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      ref="multipleTable"
      :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
      v-if="total > 0 || isSearch"
      @selection-change="onSelect"
    >
      <span slot="empty"></span>
      <vh-table-column type="selection" width="55" align="left" />

      <vh-table-column v-if="isInWebinar" width="80">
        <template slot="header" slot-scope="scope">
          排序
          <vh-tooltip effect="dark" placement="top-start" v-tooltipMove>
            <div slot="content">
              1.数值越小，排序越靠前，0排在第一位
              <br />
              2.观看端商品列表按照设置顺序展示，序号从1开始
            </div>
            <i class="iconfont-v3 saasicon_help_m icon__max_show"></i>
          </vh-tooltip>
        </template>
        <template slot-scope="scope">
          <vh-input
            v-model.number="scope.row.order_num"
            @focus="onFocus(scope.row)"
            @blur="onBlur(scope.row)"
            @keyup.enter.native="$event => $event.target.blur()"
          ></vh-input>
        </template>
      </vh-table-column>
      <vh-table-column label="ID" prop="goods_id" width="80"></vh-table-column>
      <vh-table-column label="图片" width="100">
        <template slot-scope="scope">
          <div class="product-cover">
            <img
              :src="scope.row.cover_img"
              alt=""
              v-parseImgOss="{ url: scope.row.cover_img, default: 1 }"
            />
          </div>
        </template>
      </vh-table-column>
      <vh-table-column
        label="名称"
        prop="name"
        min-width="156"
        show-overflow-tooltip
      ></vh-table-column>
      <vh-table-column label="原价" prop="price" width="110">
        <template slot-scope="scope">
          <p>
            {{ scope.row.price !== null ? `￥${scope.row.price}` : `--` }}
          </p>
        </template>
      </vh-table-column>
      <vh-table-column label="优惠价" width="110">
        <template slot-scope="scope">
          <p>
            {{ scope.row.discount_price !== null ? `￥${scope.row.discount_price}` : `--` }}
          </p>
        </template>
      </vh-table-column>
      <vh-table-column label="购买方式" width="130">
        <template slot-scope="scope">
          {{ scope.row.buy_type | buyTypeFilter }}
        </template>
      </vh-table-column>
      <vh-table-column v-if="isInWebinar" :width="!isBigScreen ? 104 : ''" label="上架设置">
        <template slot="header">
          <span class="push-status-header">
            上架设置
            <vh-tooltip effect="dark" placement="right" v-tooltipMove>
              <p slot="content">
                上架：无论直播中是否推送，都显示在商品列表；
                <br />
                推送上架：直播中推送后才显示在商品列表中，不推送则不显示，推送后状态变更为上架；
                <br />
                下架：观看端和直播间操作端不会展示商品
              </p>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.status | statusFilter }}
        </template>
      </vh-table-column>
      <vh-table-column v-if="isInWebinar" label="状态">
        <template slot-scope="scope">
          {{ scope.row.push_status | pushStatusFilter }}
        </template>
      </vh-table-column>
      <vh-table-column label="最后操作" prop="name" width="150">
        <template slot-scope="scope">
          <p>
            <vh-tooltip
              effect="dark"
              :content="generateOperator(scope.row)"
              :disabled="generateOperator(scope.row, true).length <= 8"
              placement="top"
            >
              <span>
                {{ generateOperator(scope.row, true) }}
              </span>
            </vh-tooltip>
          </p>
          <p>
            {{ scope.row.updated_at }}
          </p>
        </template>
      </vh-table-column>
      <vh-table-column
        v-if="!(!isInWebinar && !hasGoodsEditPermission)"
        label="操作"
        align="left"
        width="150"
        fixed="right"
      >
        <template slot-scope="scope">
          <vh-button
            v-if="!isInWebinar"
            v-preventReClick
            class="btns"
            type="text"
            @click="onCopy(scope.row)"
          >
            复制
          </vh-button>
          <vh-button
            v-if="!isInWebinar"
            v-preventReClick
            class="btns"
            type="text"
            @click="onEdit(scope.row)"
          >
            编辑
          </vh-button>
          <vh-button v-preventReClick class="btns" type="text" @click="onDelete(scope.row)">
            删除
          </vh-button>
          <vh-button
            v-if="isInWebinar"
            v-preventReClick
            class="btns"
            type="text"
            @click="setStatus(scope.row)"
          >
            上架设置
          </vh-button>
        </template>
      </vh-table-column>
    </vh-table>
    <SPagination
      :total="total"
      :page-size="searchParams.page_size"
      :currentPage="searchParams.page"
      @current-change="currentChange"
      @size-change="sizeChange"
    ></SPagination>
    <null-page text="未搜索到相关内容" nullType="search" v-if="total === 0 && isSearch"></null-page>
    <ProductMaterial
      v-if="isInWebinar"
      @success="getList('clearPage')"
      ref="materialRef"
    ></ProductMaterial>
    <product-status-set @success="getList" ref="statusSetRef"></product-status-set>
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import { overHidden } from '@/utils/utils.js';
  import ProductMaterial from './material.vue';
  import ProductStatusSet from './statusSet.vue';
  import { isEmbed } from '@/utils/utils';
  import {
    getAdminProductsList,
    getAdminWebinarProductsList,
    deleteAdminProduct,
    deleteWebinarProduct,
    copyAdminProduct,
    setWebinarProductOrder
  } from '@vhcl/product';

  export const buyTypeMap = {
    1: '平台购买',
    2: '外链购买',
    3: '自定义购买',
    4: '跳转小程序购买'
  };
  export const pushStatusMap = {
    0: '未推送',
    1: '推送中',
    2: '已推送'
  };
  export const statusMap = {
    0: '下架',
    1: '上架',
    2: '推送上架'
  };

  export default {
    name: 'ProductList',
    data() {
      return {
        isSearch: false,
        isBigScreen: true,
        selectIds: [], // 批量删除列表
        total: 0, // 列表总数
        searchParams: {
          searchName: '', // 搜索商品名称
          // 分页数据
          page_size: 10,
          page: 1
        },
        tableData: [] // 列表数据
      };
    },
    props: {
      isInWebinar: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    components: {
      NullPage,
      ProductMaterial,
      ProductStatusSet
    },
    computed: {
      // 是否有商品编辑权限
      hasGoodsEditPermission() {
        // 有权限：1【默认，省缺值】 无权限：2
        return this.$store.state?.permissionValue?.['goods_edit_permission'] == 1;
      }
    },
    mounted() {
      this.getList('clearPage');
      window.addEventListener('resize', this.resize);
      this.resize();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resize);
    },
    filters: {
      buyTypeFilter(val) {
        return buyTypeMap[val];
      },
      pushStatusFilter(val) {
        return pushStatusMap[val];
      },
      statusFilter(val) {
        return statusMap[val];
      },
      overHidden
    },
    methods: {
      generateOperator(row, hide) {
        const str =
          row.operator_role === '主持人' ? row.operator : `${row.operator_role}-${row.operator}`;
        if (hide) {
          return overHidden(str, 8);
        }
        return str;
      },
      resize() {
        const clientWidth = document.body.clientWidth;
        if (clientWidth < 1920) {
          this.isBigScreen = false;
        } else {
          this.isBigScreen = true;
        }
      },
      openMaterial() {
        this.$refs.materialRef.open();
      },
      createProduct() {
        this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/addProduct`);
      },
      getList(type) {
        if (type == 'clearPage') {
          this.searchParams.page = 1;
        }
        this.isSearch = this.searchParams.searchName ? true : false;
        this.requestList().then(res => {
          if (res.code == 200) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          }
        });
      },
      requestList() {
        const commonParams = {
          keyword: this.searchParams.searchName,
          pos: (this.searchParams.page - 1) * this.searchParams.page_size,
          limit: this.searchParams.page_size
        };
        if (this.isInWebinar) {
          return getAdminWebinarProductsList({
            ...commonParams,
            webinar_id: this.$route.params.str
          });
        }
        return getAdminProductsList(commonParams);
      },
      onSelect(val) {
        console.log(val);
        if (val?.length) {
          this.selectIds = val.map(item =>
            this.isInWebinar ? item.webinar_goods_id : item.goods_id
          );
        } else {
          this.selectIds = [];
        }
      },
      onFocus(row) {
        // 聚焦暂存，失焦做对比
        this._order_num = row.order_num;
      },
      // 排序
      onBlur(row) {
        if (
          row.order_num === '' ||
          row.order_num > 9999 ||
          row.order_num < 0 ||
          row.order_num == this._order_num ||
          (row.order_num > 0 && row.order_num < 1) ||
          typeof row.order_num != 'number'
        ) {
          row.order_num = this._order_num;
          return false;
        }

        setWebinarProductOrder({
          webinar_goods_id: row.webinar_goods_id,
          order_num: row.order_num
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage.success('修改成功');
              this.getList();
            } else {
              this.$vhMessage.error('修改失败');
            }
          })
          .catch(() => {
            this.$vhMessage.error('修改失败');
          });
      },
      onCopy(row) {
        copyAdminProduct({
          goods_id: row.goods_id
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage.success('复制成功');
              this.getList();
            } else {
              this.$$vhMessage.error('复制失败');
            }
          })
          .catch(() => {
            this.$vhMessage.error('复制失败');
          });
      },
      onEdit(row) {
        this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/addProduct/${row.goods_id}`);
      },
      onDelete(row) {
        this.$vhConfirm('删除后，当前商品将无法使用，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          customClass: 'zdy-message-box',
          lockScroll: false,

          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.requestDeleteProduct(row)
              .then(res => {
                if (res.code == 200) {
                  this.$vhMessage.success('删除成功');
                  this.getList('clearPage');
                  this.selectIds = [];
                } else {
                  this.$vhMessage.error('删除失败');
                }
              })
              .catch(() => {
                this.$vhMessage.error('删除失败');
              });
          })
          .catch(e => {
            this.$vhMessage({
              message: `已取消删除`,
              showClose: true,
              type: 'info',
              customClass: 'zdy-info-box'
            });
          });
      },
      requestDeleteProduct(row) {
        if (this.isInWebinar) {
          return deleteWebinarProduct({
            webinar_goods_ids: row ? [row.webinar_goods_id] : this.selectIds
          });
        }
        return deleteAdminProduct({
          goods_ids: row ? [row.goods_id] : this.selectIds
        });
      },
      setStatus(row) {
        this.$refs.statusSetRef.open(row || this.selectIds);
      },
      currentChange(val) {
        this.searchParams.page = val;
        this.getList();
      },
      sizeChange(val) {
        this.searchParams.page = 1;
        this.searchParams.page_size = val;
        this.getList();
      }
    }
  };
</script>

<style lang="less" scoped>
  .product-list-wrap {
    min-height: 572px;
    .head-operat {
      overflow: hidden;
      padding-bottom: 24px;
    }
    .oh {
      overflow: hidden;
    }
    .product-cover {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 4px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .push-status-header {
      display: inline-block;
      min-width: 80px;
      font-weight: inherit;
      white-space: nowrap;
      i {
        cursor: pointer;
      }
    }
  }
</style>
