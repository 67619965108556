<template>
  <div class="product-pushstatus-set">
    <vh-dialog title="上架设置" :visible.sync="visible" width="412px">
      <div class="sale-type-box">
        <div class="btn-label">直播中</div>
        <vh-radio-group v-model="status">
          <vh-radio :label="1">上架</vh-radio>
          <vh-radio :label="2">推送上架</vh-radio>
          <vh-radio :label="0">下架</vh-radio>
        </vh-radio-group>
      </div>
      <article>
        上架：无论直播中是否推送，都显示在商品列表；
        <br />
        推送上架：直播中推送后才显示在商品列表中，不推送则不显示，推送后状态变更为上架；
        <br />
        下架：观看端和直播间操作端不会展示商品
      </article>
      <template v-if="!Array.isArray(editProduct)">
        <div class="sale-type-box pushstatus-radio">
          <div class="btn-label">预约中</div>
          <vh-radio-group v-model="preSaleType">
            <vh-radio :label="1">预售</vh-radio>
            <vh-radio :label="0">非预售</vh-radio>
          </vh-radio-group>
        </div>
        <div class="btn-text-box" v-show="preSaleType == 1">
          <div class="btn-label">
            <span style="color: #fb3a32; margin-right: 4px">*</span>
            按钮文案
          </div>
          <vh-input
            v-model.trim="pre_sale_button_desc"
            class="btn-input"
            size="medium"
            :maxlength="15"
            show-word-limit
          ></vh-input>
        </div>
        <article>
          预售：直播开始前，完成预约的观众可购买的商品，一场直播最多支持一个预售商品，
        </article>
        <div class="sale-type-box">
          <div class="btn-label">回放中</div>
          <vh-radio-group v-model="auto_pop">
            <vh-radio :label="1">商品推屏效果</vh-radio>
            <vh-radio :label="0">非商品推屏效果</vh-radio>
          </vh-radio-group>
        </div>
        <article>
          设置后观看回放时显示商品推屏效果，回放中最多支持一个「商品推屏效果」的商品。此商品需是「上架」状态
        </article>
      </template>
      <footer>
        <vh-button plain round type="info" size="medium" @click="cancel">取消</vh-button>
        <vh-button v-preventReClick type="primary" round size="medium" @click="confirm">
          确定
        </vh-button>
      </footer>
    </vh-dialog>
  </div>
</template>

<script>
  import { setAdminProductStatus, batchChangeStatus } from '@vhcl/product';
  export default {
    data() {
      return {
        status: 0,
        visible: false,
        preSaleType: 0, // 预售类型：是否预售;(0.非预售  1.预售)
        auto_pop: 0, // 是否自动推屏(0.否  1.是)
        pre_sale_button_desc: '立即抢购',
        editProduct: null
      };
    },
    methods: {
      confirm() {
        if (Array.isArray(this.editProduct)) {
          batchChangeStatus({ webinar_goods_ids: this.editProduct, status: +this.status }).then(
            res => {
              if (res.code == 200) {
                this.$vhMessage.success('设置成功');
                this.visible = false;
                this.$emit('success');
              } else {
                this.$vhMessage.error('设置失败');
              }
            }
          );
          return;
        }
        if (this.preSaleType === 1 && !this.pre_sale_button_desc) {
          this.$vhMessage.error('请输入预售按钮文案');
          return false;
        }
        setAdminProductStatus({
          webinar_goods_id: this.editProduct?.webinar_goods_id,
          status: +this.status,
          pre_sale: this.preSaleType,
          auto_pop: this.auto_pop,
          pre_sale_button_desc: this.pre_sale_button_desc
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage.success('设置成功');
              this.visible = false;
              this.$emit('success');
            } else {
              this.$vhMessage.error('设置失败');
            }
          })
          .catch(() => {
            this.$vhMessage.error('设置失败');
          });
      },
      cancel() {
        this.visible = false;
      },
      open(product) {
        this.editProduct = product;
        if (Array.isArray(product)) {
          this.visible = true;
          return;
        }
        this.preSaleType = product?.pre_sale || 0;
        this.auto_pop = product?.auto_pop || 0;
        this.pre_sale_button_desc = product?.pre_sale_button_desc || '立即抢购';
        this.status = product.status || 0;
        this.visible = true;
      }
    }
  };
</script>

<style lang="less" scoped>
  .product-pushstatus-set {
    .pushstatus-radio {
      margin-top: 24px;
    }
    article {
      margin-top: 16px;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.45);
    }
    footer {
      margin-top: 24px;
      text-align: right;
    }

    .btn-text-box,
    .sale-type-box {
      display: flex;
      align-items: center;
      .btn-label {
        vertical-align: middle;
        font-size: 14px;
        color: #262626;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 55px;
      }
    }

    .btn-text-box {
      padding-left: 55px;
      .btn-label {
        font-size: 12px;
        width: 92px;
      }
    }
  }
</style>
